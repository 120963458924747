import { useState, useRef, useEffect } from "react";
import useOnScreen from "../hooks/useOnScreen";
import LocationDetails from "../components/location-details";
import { socials } from "../components/footer";

const FacebookFeed = () => {
  const elementRef = useRef(null);
  const isOnScreen = useOnScreen(elementRef);
  const [wasOnScreen, setWasOnScreen] = useState(false);
  const [height, setHeight] = useState(808);

  useEffect(() => {
    setHeight(elementRef?.current?.clientHeight || 808);
  }, [elementRef]);

  useEffect(() => {
    if (isOnScreen && !wasOnScreen) {
      setWasOnScreen(true);
    }
  }, [isOnScreen]);

  return (
    <div className="flex" ref={elementRef}>
      {isOnScreen || wasOnScreen ? (
        <iframe
          src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fclarkandsullivan&tabs=timeline&width=500&height=${height}&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=565926093606241`}
          loading="lazy"
          height={height}
          width="500"
          className="b-none bg-gray-50 w-full sm:w-[500px] overflow-hidden"
          scrolling="no"
          style={{ margin: "-1px" }}
          frameBorder="0"
          allowFullScreen
        />
      ) : null}
    </div>
  );
};

export default function ContactSection({ labels, locations }) {
  const [hideFacebookFeed, setHideFacebookFeed] = useState(false);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setHideFacebookFeed(isSafari);
  }, []);

  return (
    <div className="bg-gray-100">
      <div
        className={`${
          hideFacebookFeed ? "max-w-3xl" : "max-w-5xl"
        } mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8`}
      >
        <div className="text-center mb-10">
          <h2 className="text-3xl sm:text-4xl md:text-5xl tracking-tight font-extrabold text-gray-900">
            {labels.title}
          </h2>
        </div>

        <div className="relative bg-white shadow-xl rounded-3xl overflow-hidden">
          <h2 className="sr-only">Contact us</h2>

          <div className="block lg:flex flex-row">
            {/* Contact information */}
            <div className="relative overflow-hidden py-10 px-6 bg-accent-700 sm:px-10 xl:p-12">
              <div
                className="absolute inset-0 pointer-events-none sm:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 w-full h-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              {locations.map((l, index) => (
                <LocationDetails
                  titleClassName="text-lg font-medium text-white"
                  titleMarginTop="mt-6"
                  iconClassName="h-6 w-6 text-accent-200"
                  textClassName="ml-3 text-base text-accent-50"
                  textHoverClassName="hover:text-accent-100 transition-colors"
                  key={l._key}
                  data={l}
                  className={index > 0 ? "mt-12" : ""}
                />
              ))}

              <h3 className="mt-12 text-lg font-medium text-white">
                {labels.socialTitle}
              </h3>
              <p className="mt-6 text-base text-accent-50 max-w-3xl">
                {labels.socialDescription}
              </p>

              <ul role="list" className="mt-8 flex space-x-12 items-center">
                {socials.map((item) => (
                  <li key={item.name}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={item.href}
                      className="text-accent-200 hover:text-accent-100"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-7 w-7" aria-hidden="true" />
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {!hideFacebookFeed ? (
              <div className="hidden lg:block w-[500px]">
                <FacebookFeed />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
