import React from "react";
import { imageBuilder } from "../lib/sanity";
import Image from "next/legacy/image";
import Link from "next/link";
import dayjs from "dayjs";
import { blurDataURL } from "../lib/constants";

const BlogCard = ({ post }) => {
  return (
    <Link
      key={post["_id"]}
      href={`/blog/${post.slug}`}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden"
    >
      <div className="flex-shrink-0">
        <div className="relative aspect-w-16 aspect-h-9">
          <Image
            draggable={false}
            className="w-full h-full object-center object-cover bg-accent-100"
            alt=""
            layout="fill"
            placeholder="blur"
            blurDataURL={blurDataURL}
            src={imageBuilder(post.coverImage).width(1280).height(720).url()}
          />
        </div>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          {post.categories && post.categories.length ? (
            <p className="text-sm font-medium text-accent-600">
              {post.categories[0].title}
            </p>
          ) : null}

          <div className="block mt-2">
            <div className="text-xl font-semibold text-gray-900">
              {post.title}
            </div>
            <div className="mt-3 text-base text-gray-500 line-clamp-4">
              {post.description}
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            {post.author.image ? (
              <>
                <span className="sr-only">{post.author.name}</span>
                <Image
                  draggable={false}
                  className="rounded-full"
                  alt=""
                  height={40}
                  width={40}
                  placeholder="blur"
                  blurDataURL={blurDataURL}
                  src={imageBuilder(post.author.image)
                    .width(80)
                    .height(80)
                    .url()}
                />
              </>
            ) : null}
          </div>
          <div className={post.author.image ? "ml-3" : ""}>
            <p className="text-sm font-medium text-gray-900">
              {post.author.name}
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime={post.datetime}>
                {dayjs(post.date).format("MMM D, YYYY")}
              </time>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
