import {
  projectDeliveryMethods,
  projectCategories,
  teamDepartments,
} from "./constants";

export const getDeliveryMethodLabel = (value) => {
  if (!value) return "";
  return projectDeliveryMethods.find((i) => i.value === value).title;
};

export const getProjectCategoryLabel = (value) => {
  if (!value) return "";
  return projectCategories.find((i) => i.value === value).title;
};

export const getDepartmentLabel = (value) => {
  if (!value) return "";
  return teamDepartments.find((i) => i.value === value).title;
};
