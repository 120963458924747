import { useState, useRef, useEffect } from "react";
import Image from "next/legacy/image";
import { blurDataURL } from "../lib/constants";
import { imageBuilder } from "../lib/sanity";

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default function ImageSlider({ slides }) {
  const [slide, setSlide] = useState(0);

  useInterval(() => {
    const newIndex = slides && slides.length - 1 === slide ? 0 : slide + 1;
    setSlide(newIndex);
  }, 7000);

  if (!slides || !slides.length) return null;

  return slides.map((data, index) => {
    const isActive = index === slide;
    return (
      <div
        key={data._key}
        className={`absolute inset-0 ${isActive ? "z-[2]" : "z-[1]"}`}
      >
        <Image
          draggable={false}
          className={`${
            isActive ? "opacity-100" : "opacity-0"
          } h-full w-full object-cover object-center transition-opacity duration-1000 bg-accent`}
          alt=""
          layout="fill"
          priority={isActive}
          placeholder="blur"
          blurDataURL={blurDataURL}
          src={imageBuilder(data.asset)
            .width(1900)
            .height(1180)
            .crop("entropy")
            .url()}
        />
      </div>
    );
  });
}
