import get from "lodash/get";
import Image from "next/legacy/image";
import React from "react";
import Link from "next/link";
import {
  HammerIcon,
  CurrencyIcon,
  MapPinIcon,
  BriefcaseIcon,
} from "lucide-react";

import { imageBuilder } from "../lib/sanity";

import { blurDataURL } from "../lib/constants";
import {
  getDeliveryMethodLabel,
  getProjectCategoryLabel,
} from "../lib/helpers";

import {
  Card,
  CardContent,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";

export function ProjectCard({
  data: product = {},
  target = "_self",
  className = "",
}: {
  data: any;
  target?: "_self" | "_blank";
  className?: string;
}) {
  const description = get(product, "description[0].children[0].text");

  return (
    <Card
      className={cn(
        "w-full group relative hover:shadow flex flex-col max-w-md rounded-lg overflow-hidden",
        className
      )}
    >
      <div className="relative aspect-w-16 aspect-h-9 bg-gray-200 transition-opacity">
        <Image
          draggable={false}
          className="w-full h-full object-center object-cover sm:group-hover:scale-105 transition-transform"
          alt=""
          layout="fill"
          placeholder="blur"
          blurDataURL={blurDataURL}
          src={imageBuilder(product.image)
            .width(1280)
            .height(853)
            .crop("entropy")
            .url()}
        />
      </div>

      <CardContent className="p-4 space-y-3 flex-col flex-1 flex">
        <div className="space-y-2 flex-1">
          <CardTitle className="text-base leading-normal">
            {product.title}
          </CardTitle>
          <CardDescription className="line-clamp-3 text-sm">
            {description}
          </CardDescription>
        </div>

        <div className="flex flex-col items-start space-y-1 text-sm text-gray-700">
          {product.location ? (
            <div className="flex items-center gap-2">
              <MapPinIcon className="h-4 w-4 flex-shrink-0" />
              {product.location}
            </div>
          ) : null}

          {product.method ? (
            <Link
              href={`/projects?methods=${product.method}`}
              className="hover:underline flex items-center gap-2 z-10 relative"
            >
              <HammerIcon className="h-4 w-4 flex-shrink-0" />
              {getDeliveryMethodLabel(product.method)}
            </Link>
          ) : null}

          {product.categories.map((cat: string) => (
            <Link
              key={cat}
              className="flex items-center gap-2 hover:underline z-10 relative"
              href={`/projects?categories=${cat}`}
            >
              <BriefcaseIcon className="h-4 w-4 flex-shrink-0" />
              {getProjectCategoryLabel(cat)}
            </Link>
          ))}

          {product.value ? (
            <div className="flex items-center gap-2 font-semibold">
              <CurrencyIcon className="h-4 w-4 flex-shrink-0" />
              {product.value}
            </div>
          ) : null}
        </div>
      </CardContent>

      {target === "_self" ? (
        <Link
          href={`/projects/${product?.slug?.current}`}
          className="absolute inset-0 z-[1]"
        />
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          className="absolute inset-0 z-[1]"
          href={`/projects/${product.slug.current}`}
        />
      )}
    </Card>
  );
}

export default ProjectCard;
