import React from "react";
import { CheckIcon } from "@heroicons/react/24/outline";

const DeliveryMethods = ({ data }) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-accent-600 uppercase tracking-wide">
            {data.category}
          </h2>
          <p className="mt-2 text-3xl sm:text-4xl text-gray-900 font-extrabold">
            {data.title}
          </p>
          <p className="mt-4 text-lg text-gray-500">{data.description}</p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10">
            {data.items.map((feature) => (
              <div key={feature.title} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-accent-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    {feature.title}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default DeliveryMethods;
