import Head from "next/head";

import { CMS_NAME } from "../lib/constants";
import { imageBuilder } from "../lib/sanity";

const defaults = { nonTextBehavior: "remove" };
function toPlainText(blocks, opts = {}) {
  const options = Object.assign({}, defaults, opts);
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return options.nonTextBehavior === "remove"
          ? ""
          : `[${block._type} block]`;
      }
      return block.children.map((child) => child.text || "").join("");
    })
    .join("\n\n");
}

export default function Meta({
  title = CMS_NAME,
  description,
  image,
  path,
  titleSuffix = CMS_NAME,
}) {
  const pageTitle =
    path && title !== titleSuffix ? `${title} | ${titleSuffix}` : title;
  const imageUrl = image
    ? imageBuilder(image).width(1200).height(630).url()
    : null;

  if (description) {
    description =
      typeof description === "string"
        ? description
        : Array.isArray(description)
        ? toPlainText(description)
        : undefined;
  }

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} key="title" />
      <meta name="twitter:title" content={pageTitle} />

      {description ? (
        <>
          <meta name="description" content={description} key="desc" />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      ) : null}

      {imageUrl ? (
        <>
          <meta property="og:image" content={imageUrl} />
          <meta name="twitter:image" content={imageUrl} />
        </>
      ) : null}

      <meta
        property="og:url"
        content={`https://clarksullivan.com${path || ""}`}
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
}
