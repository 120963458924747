import { useState, useEffect, useRef } from "react";
import Image from "next/legacy/image";
import Link from "next/link";

import { blurDataURL, LogoText } from "../lib/constants";
import { CMS_DESCRIPTION } from "../lib/constants";
import { getHomePage } from "../lib/api";
import { imageBuilder } from "../lib/sanity";
import BlogCard from "../components/blog-card";
import DeliveryMethods from "../components/delivery-methods";
import ImageSlider from "../components/image-slider";
import Meta from "../components/meta";
import ProjectCard from "../components/project-card";
import VideoModal from "../components/video-modal";
import ContactSection from "../components/contact-section";

const Hero = ({ data, description = CMS_DESCRIPTION, labels }) => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-[3] pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-[535px] lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="h-[64px]"></div>

          <main className="pt-10 mx-auto max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28 -mt-16">
            <div className="sm:text-center lg:text-left">
              <LogoText className="max-w-md md:max-w-lg mx-auto lg:m-0" />

              <p className="mt-3 relative max-w-md md:max-w-lg mx-auto text-lg sm:text-left text-gray-500 sm:text-xl md:mt-5 lg:max-w-3xl">
                {labels.description || description}
              </p>

              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Link
                    href={labels.primaryButtonLink}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-accent-600 hover:bg-accent-700 md:py-4 md:text-lg md:px-10"
                  >
                    {labels.primaryButton}
                  </Link>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <Link
                    href={labels.secondaryButtonLink}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-accent-700 bg-accent-100 hover:bg-accent-200 md:py-4 md:text-lg md:px-10"
                  >
                    {labels.secondaryButton}
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-[65%]">
        <div className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full relative">
          <ImageSlider slides={data.images} />
        </div>
      </div>
    </div>
  );
};

const Pursuits = ({ labels }) => {
  return (
    <div className="bg-accent-600">
      <div className="max-w-3xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl md:text-5xl">
          <span className="block">{labels.title}</span>
          <span className="block mt-1">{labels.subtitle}</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-accent-200">
          {labels.description}
        </p>

        <Link
          href="/pursuits"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-accent-600 bg-white hover:bg-accent-50 sm:w-auto"
        >
          {labels.button}
        </Link>
      </div>
    </div>
  );
};

const Blog = ({ posts, labels = {} }) => {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl sm:text-4xl md:text-5xl tracking-tight font-extrabold text-gray-900">
            {labels.title}
          </h2>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <BlogCard post={post} key={post["_id"]} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Projects = ({ data, labels = {} }) => {
  const buttonLabel = labels.viewAll || "See everything";
  return (
    <div className="bg-white">
      <div className="pb-4 sm:pb-24 py-16 sm:py-24 lg:max-w-7xl lg:mx-auto lg:px-8">
        <div className="px-4 flex items-center justify-between sm:px-6 lg:px-0">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold tracking-tight text-gray-900">
            {labels.title || "Featured Projects"}
          </h2>
          <Link
            href="/projects"
            className="hidden sm:block text-sm font-semibold text-accent-600 hover:text-accent-500"
          >
            {buttonLabel}
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>

        <div className="mt-8 relative">
          <div className="relative w-full pb-6 -mb-6 overflow-x-auto">
            <div className="mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:space-x-0 lg:grid lg:grid-cols-4 lg:gap-x-8">
              {data.projects.map((product) => (
                <ProjectCard
                  className="min-w-[270px] lg:min-w-0"
                  target="_self"
                  key={product._id}
                  data={product}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="mt-12 flex px-4 sm:hidden">
          <Link
            href="/projects"
            className="text-sm font-semibold text-accent-600 hover:text-accent-500"
          >
            {buttonLabel}
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Videos = ({ data, labels = {} }) => {
  const video = data.video;
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="relative bg-white overflow-hidden">
        <div
          className="hidden lg:block lg:absolute lg:inset-0"
          aria-hidden="true"
        >
          <svg
            className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
            width={640}
            height={784}
            fill="none"
            viewBox="0 0 640 784"
          >
            <defs>
              <pattern
                id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                x={118}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              y={72}
              width={640}
              height={640}
              className="text-gray-50"
              fill="currentColor"
            />
            <rect
              x={118}
              width={404}
              height={784}
              fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
            />
          </svg>
        </div>

        <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32">
          <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <h1>
                  <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                    {labels.category}
                  </span>
                  <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl text-gray-900">
                    {video.title}
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {video.description}
                </p>

                <div className="mt-6">
                  <Link
                    href="/videos"
                    className="inline-block text-center bg-accent-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-accent-700"
                  >
                    {labels.button}
                  </Link>
                </div>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <svg
                  className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                  width={640}
                  height={784}
                  fill="none"
                  viewBox="0 0 640 784"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                      x={118}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    y={72}
                    width={640}
                    height={640}
                    className="text-gray-50"
                    fill="currentColor"
                  />
                  <rect
                    x={118}
                    width={404}
                    height={784}
                    fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                  />
                </svg>
                <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-500"
                  >
                    <span className="sr-only">
                      Watch our video to learn more
                    </span>

                    <div className="aspect-w-16 aspect-h-9 relative">
                      <Image
                        draggable={false}
                        className="w-full h-full object-center object-cover bg-accent-100"
                        alt=""
                        layout="fill"
                        placeholder="blur"
                        blurDataURL={blurDataURL}
                        src={imageBuilder(video.asset.image)
                          .width(1280)
                          .height(720)
                          .url()}
                      />
                    </div>

                    <div
                      className="absolute inset-0 w-full h-full flex items-center justify-center"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-20 w-20 text-accent-500"
                        fill="currentColor"
                        viewBox="0 0 84 84"
                      >
                        <circle
                          opacity="0.9"
                          cx={42}
                          cy={42}
                          r={42}
                          fill="white"
                        />
                        <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <VideoModal
        open={open}
        videoUrl={video.asset && video.asset.video}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

const LiveCams = ({ data, labels = {} }) => {
  const LiveCamImage = ({ data }) => {
    return (
      <Link
        className="block h-64 w-64 rounded-lg object-cover md:h-72 md:w-72 relative group overflow-hidden sm:hover:scale-105 transition"
        target="_blank"
        rel="noreferrer"
        href={`/cams/${data.key.toLowerCase()}`}
      >
        <div className="sr-only">{data.inameg}</div>
        <Image
          draggable={false}
          className="w-full h-full object-center rounded-lg object-cover bg-accent-100"
          alt={data.name}
          layout="fill"
          title={data.name}
          placeholder="blur"
          blurDataURL={blurDataURL}
          src={data.img}
        />
      </Link>
    );
  };

  return (
    <div className="bg-white mt-12">
      <div className="pt-32 overflow-hidden sm:pt-16">
        <div className="bg-accent-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative pt-48 pb-16 sm:pb-24">
              <div>
                <h2
                  id="sale-heading"
                  className="text-4xl font-extrabold tracking-tight text-white md:text-5xl"
                >
                  {labels.title}
                  <br />
                  {labels.subtitle}
                </h2>
                <div className="mt-6 text-base">
                  <Link href="/live-cams" className="font-semibold text-white">
                    {labels.button}
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>

              <div className="absolute -top-32 left-1/2 transform -translate-x-1/2 sm:top-6 sm:translate-x-0">
                <div className="ml-24 flex space-x-6 min-w-max sm:ml-3 lg:space-x-8">
                  <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div className="flex-shrink-0">
                      <LiveCamImage data={data[0]} />
                    </div>

                    <div className="mt-6 flex-shrink-0 sm:mt-0">
                      <LiveCamImage data={data[1]} />
                    </div>
                  </div>
                  <div className="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div className="flex-shrink-0">
                      <LiveCamImage data={data[2]} />
                    </div>

                    <div className="mt-6 flex-shrink-0 sm:mt-0">
                      <LiveCamImage data={data[3]} />
                    </div>
                  </div>
                  <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8">
                    <div className="flex-shrink-0">
                      <LiveCamImage data={data[4]} />
                    </div>

                    <div className="mt-6 flex-shrink-0 sm:mt-0">
                      <LiveCamImage data={data[5]} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Team = ({ data, labels = {} }) => {
  const TeamImage = ({ index }) => {
    const person = data.team[index];
    return (
      <Image
        draggable={false}
        className="w-full h-full object-center object-cover shadow-lg rounded-lg bg-accent-100"
        layout="fill"
        placeholder="blur"
        blurDataURL={blurDataURL}
        alt={person.name}
        src={imageBuilder(person.image)
          .width(176)
          .height(208)
          .dpr(2)
          .crop("entropy")
          .url()}
      />
    );
  };

  return (
    <div className="relative bg-white overflow-hidden lg:-mt-6">
      <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-[16rem] lg:pb-48">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
          <div className="sm:max-w-lg">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold tracking-tight text-gray-900">
              {labels.title || "Meet our Team"}
            </h1>
            <p className="mt-4 text-xl text-gray-500">{labels.description}</p>
          </div>
          <div>
            <div className="mt-10">
              {/* Decorative image grid */}
              <div
                aria-hidden="true"
                className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 translate-y lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-52 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100 relative shadow-lg">
                        <TeamImage index={0} />
                      </div>
                      <div className="w-44 h-52 rounded-lg overflow-hidden relative shadow-lg">
                        <TeamImage index={1} />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8 lg:mt-8">
                      <div className="w-44 h-52 rounded-lg overflow-hidden relative shadow-lg">
                        <TeamImage index={2} />
                      </div>
                      <div className="w-44 h-52 rounded-lg overflow-hidden relative shadow-lg">
                        <TeamImage index={3} />
                      </div>
                      <div className="w-44 h-52 rounded-lg overflow-hidden relative shadow-lg">
                        <TeamImage index={4} />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-52 rounded-lg overflow-hidden relative shadow-lg">
                        <TeamImage index={5} />
                      </div>
                      <div className="w-44 h-52 rounded-lg overflow-hidden relative shadow-lg">
                        <TeamImage index={6} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                href="/team"
                className="inline-block text-center bg-accent-600 border border-transparent rounded-md py-3 px-8 font-medium text-white hover:bg-accent-700"
              >
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Careers = ({ labels = {} }) => {
  return (
    <div className="relative sm:pb-24 pb-16 pt-6 sm:pt-16 bg-gray-50">
      {/* <div
        className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block"
        aria-hidden="true"
      /> */}
      <div className="max-w-7xl mx-auto bg-accent-600 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div
              className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
              aria-hidden="true"
            />
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1 rounded-3xl shadow-2xl overflow-hidden bg-white">
                {labels.image ? (
                  <Image
                    draggable={false}
                    className="object-cover bg-accent-100 object-center rounded-3xl shadow-2xl"
                    src={imageBuilder(labels.image)
                      .width(1280)
                      .height(720)
                      .url()}
                    alt=""
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={blurDataURL}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="relative bg-accent-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div
              className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block"
              aria-hidden="true"
            >
              <svg
                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-accent-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
              <svg
                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-accent-500"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={384}
                  fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                />
              </svg>
            </div>
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <h2
                className="text-3xl sm:text-4xl font-extrabold text-white"
                id="join-heading"
              >
                {labels.title}
              </h2>
              <p className="text-lg text-white">{labels.description}</p>

              <Link
                href="/careers"
                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-accent-700 hover:bg-gray-50 sm:inline-block sm:w-auto"
              >
                {labels.button}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Stats = ({ data }) => {
  if (!data.items?.length) return null;

  return (
    <div className="relative bg-gray-900">
      <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <Image
              draggable={false}
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              alt=""
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL={blurDataURL}
              src={imageBuilder(data.image)
                .width(1155)
                .height(692)
                .dpr(2)
                .crop("entropy")
                .url()}
            />

            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold text-accent-300 tracking-wide uppercase">
            {data.category}
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">
            {data.title}
          </p>
          <p className="mt-5 text-lg text-gray-300">{data.description}</p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {data.items.map((item) => (
              <p key={item._key}>
                <span className="block text-2xl font-bold text-white">
                  {item.title}
                </span>
                <span className="mt-1 block text-base text-gray-300">
                  <span className="font-medium text-white">
                    {item.subtitle}
                  </span>{" "}
                  {item.subtitleText}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial = ({ data }) => {
  function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    });
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }
  const [slide, setSlide] = useState(0);
  const [interacted, setInteracted] = useState(false);

  const items = data.items;
  useInterval(() => {
    if (interacted || items?.length < 2) return;
    const newIndex = slide && items?.length - 1 === slide ? 0 : slide + 1;
    setSlide(newIndex);
  }, 12000);

  if (!data.items) return null;
  const testimonial = items?.[slide] || {};
  return (
    <section className="py-12 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">Workcation</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>

        <div className="relative">
          {testimonial.logo ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              className="mx-auto w-28"
              src={imageBuilder(testimonial.logo).height(100).dpr(2).url()}
              alt="logo"
            />
          ) : null}

          <blockquote className="mt-10 min-h-[237px]">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>&ldquo;{testimonial.quote}&rdquo;</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                {testimonial.authorImage ? (
                  <div className="md:flex-shrink-0">
                    <div className="mx-auto h-10 w-10 rounded-full relative">
                      <Image
                        draggable={false}
                        alt=""
                        layout="fill"
                        className="rounded-full bg-white"
                        placeholder="blur"
                        blurDataURL={blurDataURL}
                        src={imageBuilder(testimonial.authorImage)
                          .width(40)
                          .height(40)
                          .dpr(2)
                          .crop("entropy")
                          .url()}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {testimonial.authorName}
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-accent-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>
                  <div className="text-base font-medium text-gray-500">
                    {testimonial.authorDetails}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>

        {items?.length > 1 ? (
          <nav
            className="flex items-center justify-center mt-6"
            aria-label="Progress"
          >
            <ol role="list" className="ml-8 flex items-center space-x-5">
              {items.map((step, index) => (
                <li key={step.quote}>
                  {index === slide ? (
                    <button
                      className="relative flex items-center justify-center"
                      aria-current="step"
                    >
                      <span
                        className="absolute w-5 h-5 p-px flex"
                        aria-hidden="true"
                      >
                        <span className="w-full h-full rounded-full bg-accent-200" />
                      </span>
                      <span
                        className="relative block w-2.5 h-2.5 bg-accent-600 rounded-full"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{step.name}</span>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setInteracted(true);
                        setSlide(index);
                      }}
                      className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400 transition"
                    >
                      <span className="sr-only">{step.name}</span>
                    </button>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        ) : null}
      </div>
    </section>
  );
};

export default function Home({ posts, data, liveCams, services, locations }) {
  const { labels = {} } = data || {};
  return (
    <div>
      <Meta {...data.seo} />
      <Hero data={data} labels={labels.hero} description={data.description} />
      <Projects data={data} labels={labels.projects} />
      <Team data={data} labels={labels.team} />
      <Stats data={labels.stats} />
      <Pursuits labels={labels.pursuits} />
      <Videos data={data} labels={labels.video} />
      <Testimonial data={labels.testimonials} />
      <LiveCams data={liveCams} labels={labels.cams} />
      <Blog posts={posts} labels={labels.blog} />
      <DeliveryMethods data={services} />
      <Careers labels={labels.join} />
      <ContactSection locations={locations} labels={labels.facebook} />
    </div>
  );
}

export async function getStaticProps({ preview = false }) {
  const { posts, data, services, locations } = await getHomePage(preview);

  const camsResponse = await fetch("https://cs-cams.firebaseio.com/list.json");
  const camsData = await camsResponse.json();
  const projects = Object.entries(camsData).map(([a, b]) => ({ key: a, ...b }));
  const liveCams = projects.filter(
    (a) => !["suspended", "unlisted", "completed"].includes(a.status)
  );

  return {
    props: { posts, preview, data, liveCams, services, locations },
    revalidate: 1,
  };
}
