import {
  EnvelopeIcon as MailIcon,
  PhoneIcon,
  MapPinIcon as LocationMarkerIcon,
} from "@heroicons/react/24/outline";

const Location = ({
  data,
  className = "",
  titleClassName = "text-2xl font-extrabold text-gray-900 sm:text-3xl",
  titleMarginTop = "mt-9",
  iconClassName = "h-6 w-6 text-gray-400",
  textClassName = "ml-3 text-base text-gray-500",
  textHoverClassName = "hover:text-gray-900 transition-colors",
}) => {
  return (
    <div className={className}>
      <h2 className={titleClassName}>{data.title}</h2>
      <div className={titleMarginTop}>
        <div className="flex">
          <div className="flex-shrink-0">
            <LocationMarkerIcon className={iconClassName} aria-hidden="true" />
          </div>
          <div className={`${textClassName} ${textHoverClassName}`}>
            <a target="_blank" rel="noreferrer" href={data.address.url}>
              <p>{data.address.line1}</p>
              <p className="mt-1">{data.address.line2}</p>
            </a>
          </div>
        </div>
        <div className="mt-6 flex">
          <div className="flex-shrink-0">
            <PhoneIcon className={iconClassName} aria-hidden="true" />
          </div>
          <div className={textClassName}>
            <p>{data.phone}</p>
            <p className="mt-1">{data.hours}</p>
          </div>
        </div>
        <div className="mt-6 flex">
          <div className="flex-shrink-0">
            <MailIcon className={iconClassName} aria-hidden="true" />
          </div>
          <div className={`${textClassName} ${textHoverClassName}`}>
            <a href={`mailto:${data.email}`}>{data.email}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
