import { useEffect, useState, useRef } from "react";

export default function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    if (!("IntersectionObserver" in window)) {
      setIsOnScreen(true);
      return;
    }

    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    if (!("IntersectionObserver" in window)) return;
    observerRef.current.observe(ref.current);
    return () => observerRef.current.disconnect();
  }, [ref]);

  return isOnScreen;
}
